import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Box,
	Divider,
	HStack,
	Icon,
	Text,
	useColorModeValue,
	Button,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Flex,
	Spacer,
	useBreakpointValue
} from "@chakra-ui/react";
import { BsArrowLeftCircle, BsEye, BsGraphUp, BsXCircleFill } from "react-icons/bs";
import { FaClipboardCheck } from "react-icons/fa";

import { RootState } from "../../redux/rootReducer";

import { TeamTasks } from "./teamTasks/TeamTasks";
import { GuidedTasks } from "./guidedTasks/GuidedTasks";
import { CheckProductTierFlag } from "../../helpers/Utilities";
import { getClickthroughURL } from "../../features/auth/auth.actions";
import TaskReporting from "./reporting/TaskReporting";

export const Tasks = () => {
	const { state }: any = useLocation();
	const navigateTo = useNavigate();
	const isMobile = useBreakpointValue({ base: true, md: false });

	const auth = useSelector((state: RootState) => state.authReducer);
	const user = useSelector((state: RootState) => state.authReducer.user);

	const [clearAllTeamTaskFilters, setClearAllTeamTaskFilters] = useState(false);
	const [clearAllGuidedTaskFilters, setClearAllGuidedTaskFilters] = useState(false);
	const [teamTasksfilterSelected, setTeamTasksFilterSelected] = useState(false);
	const [guidedTasksfilterSelected, setGuidedTasksFilterSelected] = useState(false);

	const [tabIndex, setTabIndex] = useState(state?.navData?.tabIndex || 0);
	const [pageIndex, setPageIndex] = useState(state?.navData?.pageIndex || 0);
	const [pageCount, setPageCount] = useState(state?.navData?.pageCount || 0);

	const [teamId, setTeamId] = useState(state?.navData?.teamId || 0);
	const [filter, setFilter] = useState(state?.navData?.filter || "all");
	const [category, setCategory] = useState(state?.navData?.category || "");
	const [search, setSearch] = useState(state?.navData?.search || "");

	const setTab = (index: number) => {
		// Only reset filters if we're not restoring from navigation
		if (index !== tabIndex && !state?.navData?.filters) {
			setTabIndex(index);
			setPageIndex(0);
			setTeamId(0);
			setFilter("");
			setCategory("");
			setSearch("");
		} else {
			setTabIndex(index);
		}
	};

	const getReportType = (acronym: string) => {
		switch (acronym) {
			case "TTH": return "team";
			case "GTH": return "guided";
			case "PTH": return "mandatory";
		}
	};

	const handleNavigateToTaskReport = (row: any) => {
		navigateTo(`/tasks/taskreport/${row.reportid}`, {
			state: {
				type: getReportType(row.reportacronym),
				from: "report",
				navData: {
					tabIndex: row.navData.tabIndex,
					filters: row.navData.filters,
					dateRange: row.navData.dateRange
				}
			}
		});
	};

	const clearAllFiltersPressed = () => {
		if (tabIndex === 0) {
			//team tasks tab is open
			setClearAllTeamTaskFilters(true);
		} else if (tabIndex === 1) {
			//guided tasks tab is open
			setClearAllGuidedTaskFilters(true);
		}
	};

	const handleClickThrough = async (path: string, id?: number) => {
		let url: string = await getClickthroughURL(path, id);
		window.open(url, "_self");
	};

	return (
		<Box
			m={{ base: 0, md: 5 }}
			height={{ base: "100%", md: "unset" }}
		>
			<Button
				display={{ base: "none", md: "unset" }}
				w="fit-content"
				hidden={state?.from !== "home"}
				size="sm"
				mb={2}
				variant="outline"
				color="gray.600"
				fontWeight={600}
				onClick={() => {
					navigateTo(-1);
				}}
			>
				<HStack>
					<Icon as={BsArrowLeftCircle} />
					<Text>
						{
							state?.from === "home" ? "Back to Home" :
								state?.from === "notification" && "Back to Notification"
						}
					</Text>
				</HStack>
			</Button>

			<Box
				height={{ base: "max-content", md: "unset" }}
				px={{ base: 0, md: 5 }}
				pt={5}
				pb={3}
				bg={useColorModeValue("white", "gray.900")}
				boxShadow={{ base: "none", md: "lg" }}
				rounded={{ base: "none", md: "lg" }}
			>
				<Button
					display={{ base: "unset", md: "none" }}
					hidden={state?.from !== "home"}
					size="sm"
					m={5}
					mb={8}
					textAlign="start"
					color="gray.600"
					variant="link"
					fontWeight={600}
					onClick={() => {
						navigateTo(-1);
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircle} />
						<Text>
							{
								state?.from === "home" ? "Back to Home" :
									state?.from === "notification" && "Back to Notification"
							}
						</Text>
					</HStack>
				</Button>

				<HStack
					mx={{base: 3, md: 5}}
					mb={5}
					gap={2}
				>
					<Icon
						as={FaClipboardCheck}
						verticalAlign="middle"
						fontSize="4xl"
						color="green.500"
					/>
					<Text
						fontWeight={{ base: 600, lg: 700 }}
						fontSize={{ base: "lg", lg: "2xl" }}
						color="gray.700"
					>
						Tasks
					</Text>

					<Spacer />

					{
						(
							(tabIndex === 0 && teamTasksfilterSelected) ||
							(tabIndex === 1 && guidedTasksfilterSelected)
						) &&
						<Button
							variant="ghost"
							size="xs"
							onClick={clearAllFiltersPressed}
						>
							<HStack>
								<Icon as={BsXCircleFill} color="gray.600" fontSize="sm" />
								<Text>Clear All Filters</Text>
							</HStack>
						</Button>
					}

					{
						user?.permissions?.Tasks?.read && <Button
							display={{ base: "none", md: "flex" }}
							bg="green.500"
							_hover={{ bg: "#248451" }}
							size="sm"
							color="white"
							float="right"
							leftIcon={<Icon as={FaClipboardCheck} />}
							onClick={() => {
								handleClickThrough("ManageTasks");
							}}
						>
							Manage Tasks
						</Button>
					}
					{
						user?.permissions?.Tasks?.read && <HStack
							display={{ base: "block", md: "none" }}
							justifyContent="end"
						>
							<Button
								bg="green.500"
								size="xs"
								color="white"
								_hover={{ bg: "#248451" }}
								leftIcon={<Icon as={FaClipboardCheck} />}
								onClick={() => {
									handleClickThrough("ManageTasks");
								}}
							>
								Manage Tasks
							</Button>
						</HStack>
					}
				</HStack>

				<Divider w="unset" mx={{ base: 0, md: -5 }} />

				<Tabs
					colorScheme="green"
					defaultIndex={state?.navData?.tabIndex || 0}
					onChange={(index: any) => setTab(index)}
				>
					{
						CheckProductTierFlag(user?.producttier?.ptobservedtasksflag) && <Flex
							justify={{ base: "center", md: "space-between" }}
							pt={2}
						>
							<TabList
								mx={{ base: 0, md: 5 }}
								w={{ base: "full", md: "unset" }}
								justifyContent="center"
							>
								<Tab>
									<Text
										color={tabIndex === 0 ? "green.500" : "gray.500"}
										fontWeight={tabIndex === 0 ? 700 : 500}
									>
										Team Tasks
									</Text>
								</Tab>
								{
									<Tab display={user.isTeamManager ? "flex" : "none"}>
										<Icon as={BsEye} mr={2} />
										<Text
											color={tabIndex === 1 ? "green.500" : "gray.500"}
											fontWeight={tabIndex === 1 ? 700 : 500}
										>
											Task Observations
										</Text>
									</Tab>
								}
								{
									auth.orgtaskreportstreamenabled && <Tab>
										{
											!isMobile && <Icon as={BsGraphUp} mr={2} />
										}
										<Text
											color={tabIndex === 2 ? "green.500" : "gray.500"}
											fontWeight={tabIndex === 2 ? 700 : 500}
										>
											Reports
										</Text>
									</Tab>
								}
							</TabList>
						</Flex>
					}

					<TabPanels>
						<TabPanel>
							{
								tabIndex === 0 && <TeamTasks
									tabIndex={tabIndex}
									pageCount={pageCount}
									setPageCount={setPageCount}
									pageIndex={pageIndex}
									setPageIndex={setPageIndex}
									teamId={teamId}
									setTeamId={setTeamId}
									filter={filter}
									setFilter={setFilter}
									category={category}
									setCategory={setCategory}
									search={search}
									setSearch={setSearch}
									clearAllFilters={clearAllTeamTaskFilters}
									setClearAllFilters={setClearAllTeamTaskFilters}
									setFilterSelected={setTeamTasksFilterSelected}
								/>
							}
						</TabPanel>
						<TabPanel>
							{
								tabIndex === 1 && <GuidedTasks
									tabIndex={tabIndex}
									pageCount={pageCount}
									setPageCount={setPageCount}
									pageIndex={pageIndex}
									setPageIndex={setPageIndex}
									filter={filter}
									setFilter={setFilter}
									teamId={teamId}
									setTeamId={setTeamId}
									category={category}
									setCategory={setCategory}
									search={search}
									setSearch={setSearch}
									clearAllFilters={clearAllGuidedTaskFilters}
									setClearAllFilters={setClearAllGuidedTaskFilters}
									setFilterSelected={setGuidedTasksFilterSelected}
								/>
							}
						</TabPanel>
						<TabPanel>
							{
								tabIndex === 2 && <TaskReporting onNavigateToTaskReport={handleNavigateToTaskReport} />
							}
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);
};