import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    Badge,
    Box,
    Divider,
    Icon,
    Image,
    Text,
    Button,
    VStack,
    Skeleton,
    useToast,
    HStack,
    Avatar,
    Spacer,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    useBreakpointValue,
    Stack
} from '@chakra-ui/react';
import {
    BsArrowLeftCircle,
    BsCheckCircle,
    BsCheckCircleFill,
    BsClipboardCheck,
    BsClipboardX,
    BsClock,
    BsDash,
    BsExclamationCircle,
    BsExclamationCircleFill,
    BsEye,
    BsFilePdf,
    BsPlayCircle,
    BsStopCircle
} from 'react-icons/bs';
import { FaClipboardCheck } from 'react-icons/fa';

import {
    getGuidedReportItemAttachment,
    getGuidedTaskReport,
    getMandatoryReportItemAttachment,
    getMandatoryTaskReport,
    getReportItemAttachment,
    getTaskReport
} from '../../features/tasks/task.actions';
import { TaskReportTable } from './TaskReportTable';
import { localDateTimeIgnoreToday } from '../../helpers/DayJsHelper';

import incident from "../../assets/images/incident.svg";
import hammer from "../../assets/images/hammer.svg";
import hi5 from "../../assets/images/hi5.svg";
import _, { isNull } from 'lodash';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import React from 'react';
import { CheckProductTierFlag } from '../../helpers/Utilities';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';

// Contexts
export const TaskReportContext = React.createContext<TaskReportState>({
    actions: [],
    addAction: () => { },
});

// Interfaces
interface Attachment {
    datauri: string;
    tthid: string;
    tiid: string;
    ttihapath: string;
};

interface ReportItem {
    id: string;
    item: {
        description: string;
        feedback: string;
        attachments: Attachment[];
    };
    status: string;
    signedOffBy: {
        date: string;
        by: string;
    };
    notes: string;
    data: string;
};

interface ReportData {
    taskName: string;
    teamName: string;
    path: string;
    tthdatesignedoff: string;
    pthid?: string;
    gthdatesignedoff?: string;
    pthdatesignedoff?: string;
    signedOffBy: string;
    targetUser?: string;
    passeditemcount?: number;
    faileditemcount?: number;
    pendingitemcount?: number;
    tthnotes?: string;
    gthnotes?: string;
    pthnotes?: string;
    tthfeedback?: string;
    gthfeedback?: string;
    pthfeedback?: string;
    schedule?: string;
    passed?: boolean;
    failed?: boolean;
    missed?: boolean;
    correctiveActions: Array<any>;
};

interface TaskReportState {
    actions: any[];
    addAction: (action: any) => void;
};

interface LocationState {
    navData?: {
        isMandatory?: boolean;
        isGuided?: boolean;
    };
    type?: 'mandatory' | 'guided' | 'standard';
    from?: 'actions' | 'notification' | 'report' | 'tasks';
    teamTaskId?: string;
    reportsNavData?: any;
};

const initialReportData: ReportData = {
    taskName: '',
    teamName: '',
    path: '',
    tthdatesignedoff: '',
    signedOffBy: '',
    correctiveActions: []
};

export const TaskReport = () => {
    const navigateTo = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const toast = useToast();
    const { state } = useLocation() as { state: LocationState };
    const { id } = useParams<{ id: string }>();
    const user = useSelector((state: RootState) => state.authReducer.user);
    const isManager = user?.isTeamManager;

    // Components
    const StatusCard: React.FC<{
        reportData: ReportData;
        reportPassed: boolean;
        isGuided?: boolean;
    }> = ({ reportData, reportPassed, isGuided }) => (
        <Box
            flex='1'
            minH={'215px'}
            h='fit-content'
            py={3}
            px={4}
            bg={'white'}
            border='1px solid'
            borderColor={'gray.200'}
            rounded='lg'
        >
            <HStack w='100%' alignItems='start'>
                <VStack alignItems='start'>
                    <Text
                        fontSize='lg'
                        fontWeight={600}
                        color={reportPassed ? 'gray.700' : 'red.600'}
                    >
                        {reportData.taskName} ({reportData.teamName})
                    </Text>
                    <HStack>
                        <Icon
                            as={reportPassed ? BsCheckCircleFill : BsExclamationCircleFill}
                            color={reportPassed ? 'green.500' : 'red.500'}
                        />
                        <Text fontWeight={500} fontSize='sm'>
                            {
                                `${reportPassed ? 'Passed' : 'Failed'} by ${reportData.signedOffBy} on ${localDateTimeIgnoreToday(reportData.tthdatesignedoff || reportData.gthdatesignedoff || reportData.pthdatesignedoff)}`
                            }
                        </Text>
                    </HStack>
                    {
                        <HStack pt={3}>
                            {
                                (reportData.faileditemcount ?? 0) > 0 && <Badge
                                    variant='subtle'
                                    colorScheme='red'
                                    size='lg'
                                >
                                    Failed Items: {reportData.faileditemcount}
                                </Badge>
                            }
                            <Badge
                                variant='subtle'
                                colorScheme='gray'
                                size='lg'
                            >
                                Total Items: {(reportData?.faileditemcount ?? 0) + (reportData?.pendingitemcount ?? 0) + (reportData?.passeditemcount ?? 0)}
                            </Badge>
                        </HStack>
                    }
                    {
                        (reportData.tthnotes || reportData.tthfeedback || reportData.targetUser || reportData.schedule) && <Divider pb={3} />
                    }
                    {
                        reportData.tthnotes && <Text
                            fontSize='sm'
                            textAlign='left'
                        >
                            <ReactMarkdown
                                className='task-report'
                                children={reportData.tthnotes}
                                rehypePlugins={[rehypeRaw] as any}
                            />
                        </Text>
                    }
                    {
                        reportData.tthfeedback && <Text
                            fontSize='sm'
                            textAlign='left'
                        >
                            <ReactMarkdown
                                className='task-report'
                                children={reportData.tthfeedback}
                                rehypePlugins={[rehypeRaw] as any}
                            />
                        </Text>
                    }
                    {
                        reportData.targetUser && !reportData.pthid && <>
                            <HStack>
                                <Icon as={reportPassed ? BsClipboardCheck : BsClipboardX} color='gray.500' />
                                <Text
                                    fontSize='sm'
                                    textAlign='left'
                                    fontWeight={600}
                                >
                                    This was a Observed Task
                                </Text>
                            </HStack>
                            <HStack>
                                <Text
                                    fontSize='sm'
                                    textAlign='left'
                                    fontWeight={600}
                                >
                                    Observer:
                                </Text>
                                <HStack>
                                    <Avatar name={reportData.signedOffBy} size='xs' color={'white'} />
                                    <Text
                                        fontSize='sm'
                                        textAlign='left'
                                        fontWeight={500}
                                    >
                                        {reportData.signedOffBy}
                                    </Text>
                                </HStack>
                            </HStack>
                            <HStack>
                                <Text
                                    fontSize='sm'
                                    textAlign='left'
                                    fontWeight={600}
                                >
                                    Person Observed:
                                </Text>
                                <HStack>
                                    <Avatar name={reportData.targetUser} size='xs' color={'white'} />
                                    <Text
                                        fontSize='sm'
                                        textAlign='left'
                                        fontWeight={500}
                                    >
                                        {reportData.targetUser}
                                    </Text>
                                </HStack>
                            </HStack>
                        </>
                    }
                    {
                        reportData.pthid && <>
                            <HStack color='purple.500'>
                                <Icon as={BsExclamationCircle} />
                                <Text
                                    fontSize='sm'
                                    textAlign='left'
                                    fontWeight={600}
                                >
                                    This was a mandatory task
                                </Text>
                            </HStack>
                        </>
                    }
                    {
                        reportData.schedule && <>
                            {
                                !reportData.pthid && <HStack>
                                    <Icon as={BsClock} color='gray.600' />
                                    <Text
                                        fontSize='sm'
                                        textAlign='left'
                                        fontWeight={600}
                                    >
                                        Schedule
                                    </Text>
                                </HStack>
                            }
                            <Text
                                fontSize='sm'
                                textAlign='left'
                            >
                                {reportData.schedule}
                            </Text>
                        </>
                    }
                </VStack>
                <Spacer />
                {
                    !isMobile &&
                    <Image
                        src={reportPassed ? hi5 : incident}
                        alt='Task Report'
                        boxSize='105px'
                        objectFit='contain'
                    />
                }
            </HStack>
        </Box>
    );

    const ActionCard = ({ action }: any) => {
        const navigateTo = useNavigate();

        return (
            <Box
                p={2}
                bg={action.overdue || action.isBlocked ? 'red.50' : action.nearlydue ? 'orange.50' : 'white'}
                border='1px solid'
                borderColor={action.overdue || action.isBlocked ? 'red.100' : action.nearlydue ? 'orange.100' : 'gray.100'}
                rounded='md'
                width='100%'
                boxShadow='xs'
                cursor='pointer'
                className='hover-pop'
                onClick={() => navigateTo(`/actions/action/${action.actionid}`, {
                    state: { from: 'taskreport' }
                })}
            >
                <VStack align='stretch' spacing={1}>
                    <HStack justify='space-between'>
                        <HStack>
                            {action.isCritical && <Icon as={BsExclamationCircleFill} color='red.500' />}
                            <Text color='gray.700' fontSize='sm' fontWeight='600'>
                                {action?.description}
                            </Text>
                        </HStack>
                        <HStack>
                            <Badge
                                colorScheme={
                                    action.nearlydue ? 'orange' :
                                        action.overdue || action.isBlocked ? 'red' :
                                            action.isOngoing ? 'blue' :
                                                action.isCompleted ? 'green' :
                                                    'gray'
                                }
                                variant='subtle'
                                fontWeight='500'
                                size='sm'
                            >
                                <HStack>
                                    {!action.notStarted && <Icon as={getStatusIcon(action)} />}
                                    <Text>{getStatus(action)}</Text>
                                </HStack>
                            </Badge>
                        </HStack>
                    </HStack>
                    {action?.actionduedate && (
                        <Text fontSize='xs'>
                            Due: {localDateTimeIgnoreToday(action.actionduedate)}
                        </Text>
                    )}
                    {action?.teamname || action.userforename ? (
                        <HStack spacing={2}>
                            <Avatar
                                size='xs'
                                color='white'
                                name={!isNull(action.userforename) ? `${action.userforename} ${action.usersurname}` : action?.teamname}
                            />
                            <Text fontSize='xs'>
                                Assigned to {!isNull(action.userforename) ? `${action.userforename} ${action.usersurname}` : action?.teamname}
                            </Text>
                        </HStack>
                    ) : (
                        <HStack>
                            <Avatar size='xs' />
                            <Text>Unassigned</Text>
                        </HStack>
                    )}
                </VStack>
            </Box>
        );
    };

    const ActionsCard = ({ actions }: any) => {
        const [firstAction, ...remainingActions] = actions;
        const [isExpanded, setIsExpanded] = useState(true);

        const handleExpandChange = () => {
            setIsExpanded(!isExpanded);
        };

        return (
            <Box flex="0.7" bg="white" border="1px solid" borderColor="gray.200" rounded="lg">
                {
                    CheckProductTierFlag(user?.producttier?.ptactionsflag) ? <Accordion
                        allowToggle={actions?.length > 1}
                        defaultIndex={[0]}
                        onChange={handleExpandChange}
                        w='100%'
                    >
                        <AccordionItem border="none" w='100%'>
                            <VStack alignItems="start" spacing={0} w='100%'>
                                <AccordionButton
                                    _hover={{ bg: 'transparent' }}
                                    cursor={actions?.length > 1 ? 'pointer' : 'default'}
                                    px={4}
                                    pt={3}
                                >
                                    <VStack alignItems="start" w="100%" spacing={2}>
                                        <HStack w="100%">
                                            <Text fontSize="md" fontWeight={600} color="gray.700">
                                                {actions?.length > 0 ? "Associated Actions" : "No Associated Actions"}
                                            </Text>
                                            {actions?.length > 0 && (
                                                <Badge colorScheme="facebook" size="sm">
                                                    {actions.length}
                                                </Badge>
                                            )}
                                            <Spacer />
                                            {actions?.length > 1 && <AccordionIcon />}
                                        </HStack>
                                        <HStack w="100%" className="noMargin">
                                            <Text fontSize={actions?.length < 1 ? "sm" : "xs"} color="gray.600" textAlign="left">
                                                {actions?.length > 0
                                                    ? "Below are the corrective actions tied to this task, giving a clear view of what's been done and what still needs attention."
                                                    : "There are no corrective actions associated with this task. If any are created, they will be displayed here for review."
                                                }
                                            </Text>
                                            <Spacer />
                                            <Image
                                                src={hammer}
                                                alt="Task Actions"
                                                boxSize="90px"
                                                objectFit="contain"
                                                aria-hidden="true"
                                            />
                                        </HStack>
                                    </VStack>
                                </AccordionButton>

                                {firstAction && (
                                    <Box
                                        w='100%'
                                        px={3}
                                        pt={0}
                                        pb={isExpanded || !isMobile ? 0 : 3}
                                    >
                                        <ActionCard action={firstAction} />
                                    </Box>
                                )}
                                {remainingActions.length > 0 && (
                                    <AccordionPanel px={3} pb={3} w='100%'>
                                        <VStack alignItems="start" spacing={2} width="100%">
                                            {remainingActions.map((action: any, index: number) => (
                                                <ActionCard
                                                    key={action.actionid || index}
                                                    action={action}
                                                />
                                            ))}
                                        </VStack>
                                    </AccordionPanel>
                                )}
                            </VStack>
                        </AccordionItem>
                    </Accordion> :
                        <VStack m={3} alignItems={'stretch'}>
                            <Text fontSize={'lg'} fontWeight={500}>Upgrade to Obbi Pro to unlock the full power of Actions</Text>
                            <HStack>
                                <Text fontSize={'sm'} color='gray.600'>The Actions feature in Obbi provides clubs with automated issue reporting from your checklists, as well as tracking and managing follow-up actions with your team.</Text>
                                <Spacer />
                                <Image
                                    src={hammer}
                                    alt='Task Report'
                                    boxSize='105px'
                                    objectFit='contain'
                                />
                            </HStack>
                            <HStack>
                                {
                                    isManager && <a href='https://calendly.com/gareth-macklin/45-minute-meeting-uk' target='_blank' rel='noreferrer noopener'>
                                        <Button size='sm' colorScheme='green' w='100%'>
                                            Contact Sales
                                        </Button>
                                    </a>
                                }
                                <a href='https://obbi.golf/features/' target='_blank' rel='noreferrer noopener'>
                                    <Button size='sm' w='100%'>
                                        Learn More
                                    </Button>
                                </a>
                            </HStack>
                        </VStack>
                }
            </Box>
        );
    };

    const getStatusIcon = (action: any) => {
        if (action.nearlydue || action.overdue) return BsClock;
        if (action.isBlocked) return BsStopCircle;
        if (action.isOngoing) return BsPlayCircle;
        if (action.isCompleted) return BsCheckCircle;
        return BsDash;
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<ReportData>(initialReportData);
    const [reportPassed, setReportPassed] = useState<boolean>(true);
    const [currentItems, setCurrentItems] = useState<ReportItem[]>([]);
    const [reportActions, setReportActions] = useState<any>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [canCreateActions, setCanCreateActions] = useState<boolean>(false);

    const addAction = useCallback((newAction: any) => {
        setReportActions((prevActions: any[]) => {
            const exists = prevActions.some(action => action.actionid === newAction.actionid);
            if (exists) return prevActions;

            return [...prevActions, newAction];
        });
    }, []);

    const showAttachmentError = () => {
        toast({
            title: 'We couldn\'t fetch all report attachments',
            description: 'Please try again. If this issue persists please contact your system administrator.',
            status: 'error',
            duration: 5000,
            isClosable: true
        });
    };

    const handleAttachmentsFetch = async (
        reportId: number,
        itemId: string,
        attachments: any[],
        getAttachmentFunc: typeof getMandatoryReportItemAttachment | typeof getGuidedReportItemAttachment | typeof getReportItemAttachment,
        pathKey: 'ptihapath' | 'gtihapath' | 'ttihapath'
    ): Promise<Attachment[]> => {
        const fetchedAttachments: Attachment[] = [];
        let attachmentsValid = true;

        for (const attachment of attachments) {
            try {
                const result = await getAttachmentFunc(
                    reportId,
                    Number(itemId),
                    attachment[pathKey],
                    true,
                    true
                );

                fetchedAttachments.push({
                    datauri: result.datauri,
                    tthid: reportId.toString(),
                    tiid: itemId,
                    ttihapath: attachment[pathKey]
                });
            } catch (error) {
                console.error('Attachment fetch error:', error);
                attachmentsValid = false;
            }
        }

        if (!attachmentsValid) showAttachmentError();

        return fetchedAttachments;
    };

    const getStatusText = (status: string, type: 'item' | 'action' = 'item'): string => {
        if (type === 'action') {
            switch (status) {
                case '1': return 'Completed';
                case '2': return 'Overdue';
                default: return 'Due Soon';
            }
        }

        switch (status) {
            case '0': return 'N/A';
            case '1': return 'Passed';
            case '2': return 'Failed';
            default: return '';
        }
    };

    const getStatus = (action: any) => {
        if (action.overdue) return 'Overdue';
        if (action.nearlydue) return 'Due Soon';
        if (action.isNotStarted) return 'Not Started';
        if (action.isOngoing) return 'In Progress';
        if (action.isBlocked) return 'Blocked';
        if (action.isCompleted) return 'Completed';
        return 'Unknown';
    };

    const handleNavigateBack = () => {
        switch (state?.from) {
            case 'actions':
            case 'notification':
                navigateTo(-1);
                break;
            case 'report':
                navigateTo('/tasks', {
                    state: {
                        navData: state?.navData,
                        recentlyViewedReportId: id
                    }
                });
                break;
            default:
                navigateTo(`/tasks/tasksummary/${state.teamTaskId}`, {
                    state: {
                        navData: state?.navData,
                        reportsNavData: state?.reportsNavData,
                        pageIndex,
                        pageCount,
                        isGuided: state?.navData?.isGuided,
                        from: 'tasks'
                    }
                });
        }
    };

    const handleMandatoryReport = async () => {
        try {
            const res = await getMandatoryTaskReport(Number(id));
            const status = res.report.pthstatus === '1' ? true : res.report.pthstatus === '2' ? false : true;

            const extractedActions = res.report.items.reduce((acc: any, item: any) => {
                if (item.actions && Array.isArray(item.actions)) {
                    return [...acc, ...item.actions];
                }
                return acc;
            }, []);

            const allActions = extractedActions.concat(res.report.actions);
            const uniqueActions = _.uniqBy(allActions, 'actionid');

            const formattedItems = await Promise.all(
                res.report.items.map(async (item: any) => {
                    let attachments: Attachment[] = [];
                    if (item.attachments.length > 0) {
                        attachments = await handleAttachmentsFetch(
                            res.report.pthid,
                            item.tiid,
                            item.attachments,
                            getMandatoryReportItemAttachment,
                            'ptihapath'
                        );
                    }

                    return {
                        id: item.tiid,
                        taskid: res.report.taskid,
                        tthid: res.report.tthid,
                        gthid: res.report.gthid,
                        pthid: res.report.pthid,
                        passed: res.report.passed,
                        failed: res.report.failed,
                        missed: res.report.missed,
                        newPage: item.newpage,
                        tpname: item.tpname,
                        tpdescription: item.tpdescription,
                        tpdescriptionmd: item.tpdescriptionmd,
                        status: getStatusText(item.ptihstatus),
                        schedule: res.report.schedule,
                        notes: item.ptihwrapupnotes || '',
                        data: item.datastring,
                        actions: item.actions,
                        tidatainputvariablename: item.tidatainputvariablename,
                        item: {
                            description: item.tidescription || '',
                            feedback: item.ptihfeedback || '',
                            attachments
                        },
                        signedOffBy: {
                            date: item.ptihdatechecked || '',
                            by: item.checkedby?.trim() || ''
                        },
                    };
                })
            );

            setReportActions(uniqueActions);
            updateReportState(res.report, status, formattedItems);

            const createActions = Number(user?.producttier?.ptactionlimit) > 0 ? res.orgActionCount < Number(user?.producttier?.ptactionlimit) : true;
            setCanCreateActions(createActions);
        } catch (error) {
            console.error('Mandatory report fetch error:', error);
            throw error;
        }
    };

    const handleGuidedReport = async () => {
        try {
            const res = await getGuidedTaskReport(Number(id));
            const status = res.report.gthstatus === '1' ? true : res.report.gthstatus === '2' ? false : true;

            const extractedActions = res.report.items.reduce((acc: any, item: any) => {
                if (item.actions && Array.isArray(item.actions)) {
                    return [...acc, ...item.actions];
                }
                return acc;
            }, []);

            const allActions = extractedActions.concat(res.report.actions);
            const uniqueActions = _.uniqBy(allActions, 'actionid');

            const formattedItems = await Promise.all(
                res.report.items.map(async (item: any) => {
                    let attachments: Attachment[] = [];
                    if (item.attachments.length > 0) {
                        attachments = await handleAttachmentsFetch(
                            res.report.gthid,
                            item.tiid,
                            item.attachments,
                            getGuidedReportItemAttachment,
                            'gtihapath'
                        );
                    }

                    return {
                        id: item.tiid,
                        taskid: res.report.taskid,
                        tthid: res.report.tthid,
                        gthid: res.report.gthid,
                        pthid: res.report.pthid,
                        passed: res.report.passed,
                        failed: res.report.failed,
                        missed: res.report.missed,
                        newPage: item.newpage,
                        tpname: item.tpname,
                        tpdescription: item.tpdescription,
                        tpdescriptionmd: item.tpdescriptionmd,
                        status: getStatusText(item.gtihstatus),
                        schedule: res.report.schedule,
                        notes: item.gtihwrapupnotes || '',
                        data: item.datastring,
                        actions: item.actions,
                        tidatainputvariablename: item.tidatainputvariablename,
                        item: {
                            description: item.tidescription || '',
                            feedback: item.gtihfeedback || '',
                            attachments
                        },
                        signedOffBy: {
                            date: item.gtihdatechecked || '',
                            by: item.checkedby?.trim() || ''
                        },
                    };
                })
            );

            setReportActions(uniqueActions);
            updateReportState(res.report, status, formattedItems);

            const createActions = Number(user?.producttier?.ptactionlimit) > 0 ? res.orgActionCount < Number(user?.producttier?.ptactionlimit) : true;
            setCanCreateActions(createActions);
        } catch (error) {
            console.error('Guided report fetch error:', error);
            throw error;
        }
    };

    const handleStandardReport = async () => {
        try {
            const res = await getTaskReport(Number(id));
            const status = res.report.tthstatus === '1' ? true : res.report.tthstatus === '2' ? false : true;

            const extractedActions = res.report.items.reduce((acc: any, item: any) => {
                if (item.actions && Array.isArray(item.actions)) {
                    return [...acc, ...item.actions];
                }
                return acc;
            }, []);

            const allActions = extractedActions.concat(res.report.actions);
            const uniqueActions = _.uniqBy(allActions, 'actionid');

            const formattedItems = await Promise.all(
                res.report.items.map(async (item: any) => {
                    let attachments: Attachment[] = [];

                    if (item.attachments.length > 0) {
                        attachments = await handleAttachmentsFetch(
                            res.report.tthid,
                            item.tiid,
                            item.attachments,
                            getReportItemAttachment,
                            'ttihapath'
                        );
                    }

                    return {
                        id: item.tiid,
                        taskid: res.report.taskid,
                        tthid: res.report.tthid,
                        gthid: res.report.gthid,
                        pthid: res.report.pthid,
                        cansetstatus: item.cansetstatus,
                        passed: res.report.passed,
                        failed: res.report.failed,
                        missed: res.report.missed,
                        newPage: item.newpage,
                        tpname: item.tpname,
                        tpdescription: item.tpdescription,
                        tpdescriptionmd: item.tpdescriptionmd,
                        status: getStatusText(item.ttihstatus),
                        schedule: res.report.schedule,
                        notes: item.ttihwrapupnotes || '',
                        data: item.datastring,
                        actions: item.actions,
                        tidatainputvariablename: item.tidatainputvariablename,
                        item: {
                            description: item.tidescription || '',
                            feedback: item.ttihfeedback || '',
                            attachments
                        },
                        signedOffBy: {
                            date: item.ttihdatechecked || '',
                            by: item.checkedby?.trim() || ''
                        },
                    };
                })
            );

            setReportActions(uniqueActions);
            updateReportState(res.report, status, formattedItems);

            const createActions = Number(user?.producttier?.ptactionlimit) > 0 ? res.orgActionCount < Number(user?.producttier?.ptactionlimit) : true;
            setCanCreateActions(createActions);
        } catch (error) {
            console.error('Standard report fetch error:', error);
            throw error;
        }
    };

    const updateReportState = (
        report: any,
        status: boolean,
        items: ReportItem[]
    ) => {
        setReportData({
            pthid: report.pthid,
            taskName: report.taskname,
            teamName: report.teamname,
            tthdatesignedoff: report.tthdatesignedoff || report.gthdatesignedoff || report.pthdatesignedoff,
            passeditemcount: report.passeditemcount,
            faileditemcount: report.faileditemcount,
            pendingitemcount: report.pendingitemcount,
            signedOffBy: report.signedoffby,
            targetUser: report.targetuser,
            path: report.tthpath,
            tthnotes: report.tthnotes || report.gthnotes || report.pthnotes,
            tthfeedback: report.tthfeedback || report.gthfeedback || report.pthfeedback,
            schedule: report.schedule,
            passed: report.passed,
            failed: report.failed,
            missed: report.missed,
            correctiveActions: report.correctiveActions || []
        });
        setReportPassed(status);
        setCurrentItems(items);
    };

    useEffect(() => {
        const fetchReport = async () => {
            setLoading(true);

            try {
                if (state?.navData?.isMandatory || state?.type === 'mandatory') {
                    await handleMandatoryReport();
                } else if (state?.navData?.isGuided || state?.type === 'guided') {
                    await handleGuidedReport();
                } else {
                    await handleStandardReport();
                }
            } catch (error) {
                console.error('Report fetch error:', error);
                toast({
                    title: 'Error fetching report',
                    description: 'Please try again. If this issue persists please contact your system administrator.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            } finally {
                setLoading(false);
            }
        };

        fetchReport();
    }, [id, state]);

    return (
        <TaskReportContext.Provider value={{ actions: reportActions, addAction }}>
            <Box m={{ base: 0, md: 5 }}>
                <Button
                    display={{ base: 'none', md: 'unset' }}
                    w='fit-content'
                    border='none'
                    boxShadow='sm'
                    mb={2}
                    size='sm'
                    color='gray.600'
                    variant='outline'
                    fontWeight={600}
                    onClick={handleNavigateBack}
                >
                    <HStack>
                        <BsArrowLeftCircle />
                        <Text>
                            {
                                state?.from === 'actions' ? 'Back to Action' :
                                    state?.from === 'notification' ? 'Back to Notification' :
                                        state?.from === 'report' ? 'Back to Task Reports' :
                                            'Back to Task Summary'
                            }
                        </Text>
                    </HStack>
                </Button>

                <Box
                    h={{ base: 'full', md: 'unset' }}
                    px={{ base: 0, md: 5 }}
                    pt={{ base: 5, md: 2 }}
                    pb={{ base: 5, md: 8 }}
                    bg='white'
                    rounded={{ base: 'none', md: 'lg' }}
                    boxShadow={{ base: 'none', md: 'lg' }}
                >
                    <Button
                        display={{ base: 'unset', md: 'none' }}
                        mx={3}
                        mt={2}
                        mb={4}
                        size='sm'
                        color='gray.600'
                        variant='link'
                        fontWeight={600}
                        onClick={handleNavigateBack}
                    >
                        <HStack>
                            <BsArrowLeftCircle />
                            <Text>
                                {
                                    state?.from === 'actions' ? 'Back to Action' :
                                        state?.from === 'notification' ? 'Back to Notification' :
                                            state?.from === 'report' ? 'Back to Task Reports' :
                                                'Back to Task Summary'
                                }
                            </Text>
                        </HStack>
                    </Button>
                    <HStack p={3} pb={5}>
                        <Icon
                            mr={3}
                            as={state?.navData?.isGuided ? BsEye : FaClipboardCheck}
                            verticalAlign='middle'
                            fontSize='4xl'
                            color='green.500'
                        />
                        <Text
                            fontWeight={{ base: 600, lg: 700 }}
                            fontSize={{ base: 'xl', lg: '2xl' }}
                            textAlign='left'
                            color='gray.700'
                        >
                            {state?.navData?.isGuided ? 'Guided Task Report' : 'Task Report'}
                            <Badge
                                hidden={loading}
                                colorScheme={reportData.passed ? 'green' : reportData.failed ? 'red' : reportData.missed ? 'orange' : 'gray'}
                                variant='subtle'
                                ml={2}
                                fontSize='sm'
                            >
                                {reportData.passed ? 'Passed' : reportData.failed ? 'Failed' : reportData.missed ? 'Missed' : 'Unknown'}
                            </Badge>
                        </Text>
                        <Spacer />
                        {
                            !isMobile && <a
                                href={`${process.env.REACT_APP_OBBI_API_URL}/taskReports/${reportData.pthid ? 'periodic' : (reportData.targetUser && !reportData.pthid) ? 'guided' : 'team'}TaskReportPDF?id=${id}`}
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <Button size='sm'>
                                    <HStack>
                                        <Icon as={BsFilePdf} />
                                        <Text>View as PDF</Text>
                                    </HStack>
                                </Button>
                            </a>
                        }
                    </HStack>

                    <Divider w='unset' mx={{ base: 0, md: -5 }} />

                    <Box
                        mx={3}
                        mb={4}
                        mt={5}
                    >
                        <Skeleton isLoaded={!loading} rounded='lg'>
                            <Stack
                                direction={{ base: 'column', xl: 'row' }}
                                spacing={4}
                                align='stretch'
                                w='100%'
                            >
                                <StatusCard
                                    reportData={reportData}
                                    reportPassed={reportPassed}
                                    isGuided={state?.navData?.isGuided}
                                />
                                <ActionsCard actions={reportActions} />
                            </Stack>
                        </Skeleton>
                    </Box>
                    {
                        currentItems.length > 0 && !loading && (
                            <Box mx={3}>
                                <TaskReportTable
                                    currentItems={currentItems}
                                    setCurrentItems={setCurrentItems}
                                    pageCount={pageCount}
                                    pageIndex={pageIndex}
                                    setPageIndex={setPageIndex}
                                    loading={loading}
                                    isGuided={state?.navData?.isGuided}
                                    canCreateActions={canCreateActions}
                                />
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </TaskReportContext.Provider>
    );
};

export default TaskReport;