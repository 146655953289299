import { _delete, getPDFWithParams, getWithParams, patch, post, postAttachment, put } from "../../api/axiosClient";
import UpdateTaskAttempt from "../../types/UpdateTaskAttempt";

export async function getTasks(
    sort: string,
    filter: string,
    kind: string,
    team: number,
    category: string,
    search: string,
    page: string,
    isManager: boolean,
    pagesize: string = "6"
): Promise<any> {
    let taskResult: any = {
        pageCount: 0,
        overdueTaskCount: 0,
        tasksDueTodayCount: 0,
        tasksDueThisWeekCount: 0,
        tasks: [],
        mandatoryTasks: [],
        categories: [],
        teams: []
    };

    const filterRequiredMandatoryTasks = (task: any) => { return task.required };

    await getWithParams("tasks/all", {
        sort,
        filter,
        kind,
        team,
        category,
        search,
        page,
        pagesize,
        mustHaveCanStartTeamTask: isManager ? false : true,
        mustHaveCanStartTTorPT: isManager,
        countTaskAttempts: true,
    })
        .then((res: any) => {
            taskResult.mandatoryTasks = res.data.availablePeriodicTasks?.filter(filterRequiredMandatoryTasks);
            taskResult.tasks = res.data.tasks;
            taskResult.pageCount = res.data.pageCount;
            taskResult.overdueTaskCount = res.data.overdueTaskCount;
            taskResult.tasksDueTodayCount = res.data.tasksDueTodayCount;
            taskResult.tasksDueThisWeekCount = res.data.tasksDueThisWeekCount;
            taskResult.categories = res.data.availableCategories

            const teamsManaged = res.data.teamsManaged;
            let teamMemberships = res.data.teamMemberships;

            for (let i = 0; i < teamsManaged.length; i++) {
                const currentTeam = teamsManaged[i];

                for (let j = 0; j < teamMemberships.length; j++) {
                    if (currentTeam.teamid === teamMemberships[j].teamid) {
                        teamMemberships.splice(j, 1);
                    }
                }
            }

            taskResult.teams = teamsManaged.concat(teamMemberships);
        })
        .catch((error: any) => {
            throw error;
        });

    return taskResult;
}

export async function getGuidedTasks(
    sort: string,
    filter: string,
    kind: string,
    team: number,
    category: string,
    search: string,
    page: string,
    pagesize: string = "6"
): Promise<any> {
    let taskResult: any = {
        "pageCount": 0,
        "overdueTaskCount": 0,
        "tasksDueTodayCount": 0,
        "tasksDueThisWeekCount": 0,
        "tasks": [],
        "categories": [],
        "teams": [],
        "observationsInProgressCount": 0,
        "observationsRequiredCount": 0,
    };

    await getWithParams("tasks/all", {
        sort,
        filter,
        kind,
        team,
        category,
        search,
        page,
        pagesize,
        mustHaveCanStartGuidedTask: true,
        countTaskAttempts: true,
    })
        .then((res: any) => {
            taskResult.tasks = res.data.tasks;
            taskResult.pageCount = res.data.pageCount;
            taskResult.overdueTaskCount = res.data.overdueTaskCount;
            taskResult.tasksDueTodayCount = res.data.tasksDueTodayCount;
            taskResult.tasksDueThisWeekCount = res.data.tasksDueThisWeekCount;
            taskResult.categories = res.data.availableCategories;

            const teamsManaged = res.data.teamsManaged;
            let teamMemberships = res.data.teamMemberships;

            for (let i = 0; i < teamsManaged.length; i++) {
                const currentTeam = teamsManaged[i];

                for (let j = 0; j < teamMemberships.length; j++) {
                    if (currentTeam.teamid === teamMemberships[j].teamid) {
                        teamMemberships.splice(j, 1);
                    }
                }
            }
            taskResult.teams = teamsManaged.concat(teamMemberships);

            taskResult.observationsInProgressCount = res.data.observationsInProgressCount;
            taskResult.observationsRequiredCount = res.data.observationsRequiredCount;
        })
        .catch((error: any) => {
            throw error;
        });

    return taskResult;
}

export async function getTask(id: number): Promise<any> {
    let taskResult: any = {
        task: {},
        pages: [],
        teamTaskAttempts: [],
        guidedTaskAttempts: [],
        trainingStatus: {},
        numberOfSteps: 0,
        myLatestAttempt: null
    };

    await getWithParams("tasks/task", { id })
        .then((res: any) => {
            taskResult.task = res.data.task;
            taskResult.pages = res.data.pages;
            taskResult.teamTaskAttempts = res.data.teamTaskAttempts;
            taskResult.guidedTaskAttempts = res.data.guidedTaskAttempts;
            taskResult.trainingStatus = res.data.trainingRequired;
            taskResult.numberOfSteps = res.data.itemCount;
            taskResult.myLatestAttempt = res.data.myLatestAttempt || res.data.myLatestPeriodicTaskAttempt;
        })
        .catch((error: any) => {
            throw error;
        });

    return taskResult;
}

export async function getTaskReports(team: number, task: number, page: number): Promise<any> {
    let reportsResult: any = {};

    await getWithParams("taskReports/teamTaskReports", {
        task,
        team,
        page,
        pagesize: 6
    })
        .then((res: any) => {
            reportsResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return reportsResult;
}

export async function getGuidedTaskReports(team: number, task: number, page: number): Promise<any> {
    let reportsResult: any = {};

    await getWithParams("taskReports/guidedTaskReports", {
        task,
        team,
        page,
        pagesize: 6,
    })
        .then((res: any) => {
            reportsResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return reportsResult;
}

export async function getTaskReport(id: number): Promise<any> {
    let reportResult: any = {};

    await getWithParams("taskReports/teamTaskReport", { id })
        .then((res: any) => {
            reportResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return reportResult;
}

export async function getGuidedTaskReport(id: number): Promise<any> {
    let reportResult: any = {};

    await getWithParams("taskReports/guidedTaskReport", { id })
        .then((res: any) => {
            reportResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return reportResult;
}

export async function getMandatoryTaskReport(id: number): Promise<any> {
    let reportResult: any = {};

    await getWithParams("taskReports/periodicTaskReport", { id })
        .then((res: any) => {
            reportResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return reportResult;
}

export async function getQrCode(id: number): Promise<any> {
    let result: any = {};

    await getWithParams("tasks/qrCode", {
        id,
        datauri: true
    })
        .then((res: any) => {
            result = res.data.datauri;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getTeamTaskAttempts(id: number): Promise<any> {
    let result: any = {};

    await getWithParams("tasks/teamTaskAttempts", { id })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getGuidedTaskAttempts(id: number): Promise<any> {
    let result: any = {};

    await getWithParams("tasks/guidedTaskAttempts", { id })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getGuidedObservations(id: number): Promise<any> {
    let result: any = {};

    await getWithParams("tasks/guidedTaskAttempts", { id })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function startTaskAttempt(
    id: number,
    kind?: number,
    targetuser?: number
): Promise<any> {
    let taskAttempt: any = {};

    await post("tasks/attempt", { id, kind, targetuser })
        .then((res: any) => {
            taskAttempt = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return taskAttempt;
}

export async function getTaskAttempt(id: Number): Promise<any> {
    let taskAttempt: any = {};

    await getWithParams("tasks/attempt", { id })
        .then((res: any) => {
            taskAttempt = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return taskAttempt;
}

export async function updateTaskAttempt(attempt: UpdateTaskAttempt): Promise<any> {
    let taskAttempt: any = {};

    await put("tasks/attempt", attempt)
        .then((res: any) => {
            taskAttempt = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return taskAttempt;
}

export async function deleteTaskAttempt(id: Number): Promise<boolean> {
    let result: boolean = true;

    await _delete("tasks/attempt", { "id": id })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getTaskItem(attempt: number, item: number): Promise<any> {
    let result: any = { item: {}, value: {}, state: {} };

    await getWithParams("tasks/item", { attempt, item })
        .then((res: any) => {
            result.item = res.data.item;
            result.value = res.data.value;
            result.state = res.data.state;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function patchTaskItem(data: any): Promise<any> {
    let result: any = { item: {}, value: {}, state: {} };

    await patch("tasks/item", data)
        .then((res: any) => {
            result.item = res.data.item;
            result.value = res.data.value;
            result.state = res.data.state;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function getItemThumbnail(
    attempt: number,
    item: number,
    attachment: string,
    thumbnail: boolean = true,
    datauri: boolean = true
): Promise<any> {
    let result: any = { datauri: "", attachment: {} };

    await getWithParams("tasks/itemAttachment", {
        attempt,
        item,
        attachment,
        thumbnail,
        datauri
    })
        .then((res: any) => {
            result.datauri = res.data.datauri;
            result.attachment = res.data.attachment;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function getAttemptThumbnail(
    attempt: number,
    item: number,
    attachment: string
): Promise<any> {
    let result: any = { datauri: "", attachment: {} };

    await getWithParams("tasks/attemptAttachment", {
        attempt,
        item,
        attachment,
        thumbnail: true,
        datauri: true
    })
        .then((res: any) => {
            result.datauri = res.data.datauri;
            result.attachment = res.data.attachment;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function getAttemptAttachment(
    attempt: number,
    item: number,
    attachment: string
): Promise<any> {
    let result: any = { datauri: "", attachment: {} };

    await getWithParams("tasks/attemptAttachment", {
        attempt,
        item,
        attachment,
        datauri: true
    })
        .then((res: any) => {
            result.datauri = res?.data?.datauri;
            result.attachment = res?.data?.attachment;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function getReportItemAttachment(
    report: number,
    item: number,
    attachment: string,
    datauri: boolean,
    thumbnail: boolean
): Promise<any> {
    let result: any = {};

    await getWithParams("taskReports/teamTaskReportAttachment", { report, item, attachment, datauri, thumbnail })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function getGuidedReportItemAttachment(
    report: number,
    item: number,
    attachment: string,
    datauri: boolean,
    thumbnail: boolean
): Promise<any> {
    let result: any = {};

    await getWithParams("taskReports/guidedTaskReportAttachment", { report, item, attachment, datauri, thumbnail })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function getMandatoryReportItemAttachment(
    report: number,
    item: number,
    attachment: string,
    datauri: boolean,
    thumbnail: boolean
): Promise<any> {
    let result: any = {};

    await getWithParams("taskReports/periodicTaskReportAttachment", { report, item, attachment, datauri, thumbnail })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function postAttemptAttachment(
    attemptId: number,
    itemId: number,
    file: File
): Promise<any> {
    let result: any = {};

    let formData = new FormData();
    formData.append("attempt", attemptId.toString());
    formData.append("item", itemId.toString());
    formData.append("file", file);

    await postAttachment(`tasks/attemptAttachment`, formData)
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function getItemAttachments(
    attempt: number,
    item: number,
    attachment: string
): Promise<any> {
    let result: any = { datauri: "", attachment: {} };

    await getWithParams("tasks/itemAttachment", {
        attempt,
        item,
        attachment,
        datauri: true
    })
        .then((res: any) => {
            result = res.data.datauri;
        })
        .catch((error: any) => {
            throw error;
        })

    return result;
}

export async function deleteAttemptAttachment(
    attempt: number,
    item: number,
    attachment: string
) {
    return (
        await _delete("tasks/attemptAttachment", { attempt, item, attachment })
            .then((res: any) => { })
            .catch((error: any) => {
                throw error;
            })
    );
}

export async function passTaskAttempt(
    id: number,
    feedback?: string,
    pincode?: any
): Promise<any> {
    let result: any = {};

    await post("tasks/pass", { id, feedback, pincode })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function failTaskAttempt(
    id: number,
    feedback?: string,
    pincode?: any
): Promise<any> {
    let result: any = {};

    await post("tasks/fail", { id, feedback, pincode })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function createActionFromTaskItem(
    task: number,
    item: number,
    description: string,
    tth?: number,
    gth?: number,
    pth?: number
): Promise<any> {
    let result: any = {};

    await post("taskReports/correctiveAction", { task, item, description, tth, gth, pth })
        .then((res: any) => {
            if (!res.data.success) throw new Error(res?.data?.message);
            result = res.data.action;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}