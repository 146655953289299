import { Fragment, useContext, useMemo, useState } from 'react';
import { isEmpty, isNull } from 'lodash';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import {
    Badge,
    Icon,
    Text,
    VStack,
    Image,
    SimpleGrid,
    HStack,
    Avatar,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useBreakpointValue,
    Box,
    Divider,
    ModalFooter,
    Button,
    Flex,
    Textarea,
    useToast,
} from '@chakra-ui/react';
import {
    BsCheckCircle,
    BsCheckCircleFill,
    BsClock,
    BsDash,
    BsDashCircle,
    BsDashCircleFill,
    BsPlayCircle,
    BsPlusSquare,
    BsStopCircle,
    BsWrench,
    BsXCircleFill
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { createActionFromTaskItem, getGuidedReportItemAttachment, getMandatoryReportItemAttachment, getReportItemAttachment } from '../../features/tasks/task.actions';
import { localDateTimeIgnoreToday } from '../../helpers/DayJsHelper';
import ObbiTable from '../common/ObbiTable';
import { TaskReportContext } from './TaskReport';

interface TaskReportTableProps {
    currentItems: any[];
    setCurrentItems: (items: any[]) => void;
    canCreateActions: boolean;
    isGuided?: boolean;
    pageCount: number;
    pageIndex: number;
    setPageIndex: (index: number) => void;
    loading: boolean;
};

export const TaskReportTable: React.FC<TaskReportTableProps> = ({
    currentItems,
    setCurrentItems,
    canCreateActions
}) => {
    const [activeImage, setActiveImage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionDescription, setActionDescription] = useState('');
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const { addAction } = useContext(TaskReportContext);

    const isMobile = useBreakpointValue({ base: true, md: false });
    const isLarge = useBreakpointValue({ base: true, xl: false });
    const toast = useToast();
    const navigateTo = useNavigate();

    const getStatus = (action: any) => {
        if (action.overdue) return 'Overdue';
        if (action.nearlydue) return 'Due Soon';
        if (action.isNotStarted) return 'Not Started';
        if (action.isOngoing) return 'In Progress';
        if (action.isBlocked) return 'Blocked';
        if (action.isCompleted) return 'Completed';
        return 'Unknown';
    };

    const getReportColor = (status: string) => {
        switch (status) {
            case 'N/A': return isMobile ? 'gray' : '';
            case 'Passed': return isMobile ? 'gray' : '';
            case 'Failed': return 'red';
            default: return 'white';
        }
    };

    const getReportBorder = (status: string) => {
        switch (status) {
            case 'N/A': return '1px solid #edf2f7';
            case 'Failed': return '1px solid #fed7d7';
            case 'Passed': return '1px solid #edf2f7';
        }
    };

    const handleActionClick = (item: any) => {
        setSelectedItem(item);
        setActionDescription('');
        setIsActionModalOpen(true);
    };

    const handleCreateAction = async () => {
        try {
            const result = await createActionFromTaskItem(
                selectedItem?.taskid,
                selectedItem?.id,
                actionDescription,
                selectedItem?.tthid,
                selectedItem?.gthid,
                selectedItem?.pthid
            );

            addAction(result);

            const updatedItems = currentItems.map((item: any) => {
                if (item.id === selectedItem?.id) {
                    return {
                        ...item,
                        actions: [...(item.actions || []), result]
                    };
                }
                return item;
            });
            setCurrentItems(updatedItems);

            toast({
                title: 'Action created successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });

            setIsActionModalOpen(false);
        } catch (error) {
            toast({
                title: 'Failed to create action',
                description: 'Please try again or contact support if the issue persists.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    const getCurrentAttachment = async (attachment: any, item?: any) => {
        let currentAttchment: any = {};

        if (item.pthid) {
            currentAttchment = await getMandatoryReportItemAttachment(
                attachment.tthid,
                attachment.tiid,
                attachment.ttihapath,
                true,
                false
            );
        } else if (item.gthid) {
            currentAttchment = await getGuidedReportItemAttachment(
                attachment.tthid,
                attachment.tiid,
                attachment.ttihapath,
                true,
                false
            );
        } else {
            currentAttchment = await getReportItemAttachment(
                attachment.tthid,
                attachment.tiid,
                attachment.ttihapath,
                true,
                false
            );
        }

        setActiveImage(currentAttchment.datauri);
        setIsOpen(true);
    };

    const renderImage = () => (
        <Modal size="xl" isCentered isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton _hover={{ bg: '' }} _active={{ bg: '' }} />
                <ModalBody>
                    <Image src={activeImage} m="5px auto 15px auto" />
                </ModalBody>
                <Button m={5} mt={0} colorScheme="green" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </ModalContent>
            <ModalFooter />
        </Modal>
    );

    const renderActionModal = () => (
        <Modal size="xl" isOpen={isActionModalOpen} onClose={() => setIsActionModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Action</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4} alignItems="baseline">
                        <HStack>
                            <Text>Creating action for:</Text>
                            <Text fontWeight={600}>{selectedItem?.item?.description}</Text>
                        </HStack>
                        <Textarea
                            autoFocus
                            value={actionDescription}
                            onChange={(e) => setActionDescription(e.target.value)}
                            placeholder="Enter action description..."
                            rows={4}
                        />
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="gray" mr={3} onClick={() => setIsActionModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        colorScheme="green"
                        isDisabled={!actionDescription.trim()}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCreateAction();
                        }}
                    >
                        Create Action
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );

    const getRowProps = (row: any) => {
        if (row.original.isDescriptionRow) {
            return {
                bg: 'gray.50',
                borderBottom: '1px solid',
                borderColor: 'gray.100',
                fontWeight: 500
            };
        }

        if (row.original.isDetailRow) {
            return {
                bg: 'white',
                borderBottom: '1px solid',
                borderColor: 'gray.100'
            };
        }

        return {
            bgColor: row.original.status === 'Failed' && row.original.cansetstatus ? 'red.50'
                : row.original.status === 'N/A' && row.original.cansetstatus ? 'gray.50'
                    : `${getReportColor(row.original.status)}.50`,
            borderBottom: row.original.status === 'Failed'
                ? '1px solid #FED7D7'
                : getReportBorder(row.original.status)
        };
    };

    const tableData = useMemo(() => {
        const transformedData: any[] = [];

        currentItems.forEach((item: any) => {
            const hasDescription = (item.tpname !== null && !isEmpty(item.tpname)) || (item.tpdescription !== null && !isEmpty(item.tpdescription));

            if (item.newPage && hasDescription) {
                transformedData.push({
                    id: `${item.id}-desc`,
                    isDescriptionRow: hasDescription,
                    tpname: item.tpname,
                    status: '',
                    item: { description: item.tpdescriptionmd, attachments: [] },
                    data: '',
                    signedOffBy: {},
                    createAction: false
                });
            }

            transformedData.push(item);

            const hasDetails = item.item?.attachments?.length > 0 &&
                !(item.item?.attachments?.length === 1 && item.tidatainputvariablename === 'signature');

            if (hasDetails) {
                transformedData.push({
                    id: `${item.id}-details`,
                    isDetailRow: true,
                    originalItem: item,
                    status: '',
                    data: '',
                    signedOffBy: {},
                    createAction: false
                });
            }
        });

        return transformedData;
    }, [currentItems]);

    const columns = useMemo(() => [
        {
            Header: 'ITEM',
            accessor: 'status',
            disableSortBy: true,
            Cell: (cell: any) => {
                if (cell.row.original.isDescriptionRow) {
                    return (
                        (cell.row.original.tpname || cell.row.original.item.description) && (
                            <VStack alignItems="baseline" whiteSpace="pre-wrap" gap={2} w="100%">
                                {cell.row.original.tpname && (
                                    <Text fontSize="lg" fontWeight={500}>
                                        {cell.row.original.tpname}
                                    </Text>
                                )}
                                {cell.row.original.item.description && (
                                    <ReactMarkdown
                                        className="task-report"
                                        children={cell.row.original.item.description}
                                        rehypePlugins={[rehypeRaw] as any}
                                    />
                                )}
                            </VStack>
                        )
                    );
                }

                if (cell.row.original.isDetailRow) {
                    const item = cell.row.original.originalItem;
                    return (
                        (item.item.attachments?.length > 0 && item.tidatainputvariablename !== 'signature') && (
                            <VStack alignItems="start" gap={2} ml={'30px'}>
                                <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
                                    {item.item.attachments.map((a: any, i: number) => (
                                        <Image
                                            key={i}
                                            className="hover-pop"
                                            cursor="pointer"
                                            src={a.datauri}
                                            fallbackSrc="https://via.placeholder.com/120"
                                            alt={a.fileName}
                                            boxSize={item.tidatainputvariablename === 'signature' ? '' : '7em'}
                                            w={item.tidatainputvariablename === 'signature' ? '160px' : '7em'}
                                            boxShadow="xs"
                                            objectFit="cover"
                                            border="1px solid"
                                            borderColor="gray.100"
                                            rounded="lg"
                                            onClick={() => {
                                                getCurrentAttachment(a, item)
                                            }}
                                        />
                                    ))}
                                </SimpleGrid>
                            </VStack>
                        )
                    );
                }

                return (
                    <VStack alignItems="start">
                        <HStack alignItems="start" gap={1}>
                            {
                                cell.row.values.status === 'Passed' ? <Icon as={BsCheckCircleFill} color="green.500" fontSize={'lg'} mt={'1px'} /> :
                                    cell.row.values.status === 'Failed' ? <Icon as={BsXCircleFill} color="red.500" fontSize={'lg'} mt={'1px'} /> :
                                        cell.row.values.status === 'N/A' && cell?.row.original.cansetstatus ? <Icon as={BsDashCircleFill} color="gray.500" fontSize={'lg'} mt={'1px'} /> :
                                            <Icon as={BsCheckCircleFill} color="transparent" fontSize={'lg'} />
                            }
                            <Text
                                fontSize="sm"
                                whiteSpace="pre-wrap"
                                fontWeight={cell.row.values.status === 'Failed' ? 600 : 500}
                                color={cell.row.values.status === 'Failed' ? ' ' : 'inherit'}
                            >
                                {cell?.row.original.item?.description}
                            </Text>
                        </HStack>
                    </VStack>
                );
            }
        },
        {
            Header: 'RECORDED DATA',
            accessor: 'data',
            disableSortBy: true,
            Cell: (cell: any) => {
                const item = cell.row.original;

                if (item.isDescriptionRow) return null;
                
                return (
                    <VStack alignItems="baseline" gap={1}>
                        {
                            !(item.item.attachments?.length === 1 && item.tidatainputvariablename === 'signature') && <Text
                                fontWeight={cell.row.values.status === 'Failed' ? 600 : 500}
                                color={cell.row.values.status === 'Failed' ? '' : 'inherit'}
                            >
                                {cell?.value || <Icon as={BsDash} />}
                            </Text>
                        }
                        {
                            item.item.attachments?.length === 1 && item.tidatainputvariablename === 'signature' && (
                                <>
                                    {item.item.attachments.map((a: any, i: number) => (
                                        <Image
                                            key={i}
                                            w="180px"
                                            bg="transparent"
                                            className="hover-pop"
                                            cursor="pointer"
                                            src={a.datauri}
                                            fallbackSrc="https://via.placeholder.com/120"
                                            alt={a.fileName}
                                            rounded="lg"
                                            onClick={() => getCurrentAttachment(a, item)}
                                        />
                                    ))}
                                </>
                            )
                        }
                        {
                            !isEmpty(cell?.row.original.notes) && (
                                <HStack color={'red.600'}>
                                    <Text fontWeight={600}>Notes:</Text>
                                    <Text>{cell?.row.original.notes}</Text>
                                </HStack>
                            )
                        }
                        {
                            !isEmpty(cell.row.original.item.feedback) && <VStack alignItems="baseline" w="100%">
                                <Flex flexDirection="row" gap={2.5} maxW="lg">
                                    <Avatar
                                        size="xs"
                                        color="white"
                                        name={cell.row.original.signedOffBy?.by}
                                        alignSelf="end"
                                    />
                                    <Flex direction="column" alignItems="flex-start">
                                        <Box
                                            px={4}
                                            py={2}
                                            bg={cell.row.values.status === 'Failed' ? 'red.100' : 'gray.100'}
                                            color="gray.900"
                                            borderRadius="2xl"
                                            borderBottomLeftRadius={0}
                                        >
                                            <Text whiteSpace="pre-wrap" fontSize="sm">
                                                <ReactMarkdown
                                                    children={cell.row.original.item.feedback}
                                                    className="task-report"
                                                    rehypePlugins={[rehypeRaw] as any}
                                                />
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Flex>
                                <Text fontSize="8pt">
                                    Comment from {cell.row.original.signedOffBy?.by}
                                </Text>
                            </VStack>
                        }
                        {
                            cell.row.original.actions?.length > 0 && <>
                                {item?.actions?.length > 0 && (
                                    <VStack alignItems="baseline">
                                        <HStack>
                                            <Icon as={BsWrench} color="gray.500" />
                                            <Text fontWeight={500} fontSize="sm">
                                                Associated Actions:
                                            </Text>
                                        </HStack>
                                        {item.actions.map((a: any, i: number) => (
                                            <Box key={i} onClick={() => navigateTo(`/actions/action/${a.actionid}`, {
                                                state: { from: 'taskreport' }
                                            })}>
                                                <HStack>
                                                    <Text fontSize="sm" _hover={{ textDecoration: 'underline' }}>
                                                        {a.actiondescription}
                                                    </Text>
                                                    <Badge
                                                        colorScheme={a.nearlydue ? 'orange' : a.overdue || a.isBlocked ? 'red' : a.isOngoing ? 'blue' : a.isCompleted ? 'green' : 'gray'}
                                                        variant="subtle"
                                                        fontWeight="500"
                                                        size="sm"
                                                    >
                                                        <HStack>
                                                            {!a.notStarted && <Icon as={(a.nearlydue || a.overdue) ? BsClock : a.isBlocked ? BsStopCircle : a.isOngoing ? BsPlayCircle : a.isCompleted ? BsCheckCircle : BsDash} />}
                                                            <Text>{getStatus(a)}</Text>
                                                        </HStack>
                                                    </Badge>
                                                </HStack>
                                            </Box>
                                        ))}
                                    </VStack>
                                )}
                            </>
                        }
                    </VStack>
                );
            }
        },
        {
            Header: 'COMPLETED',
            accessor: 'signedOffBy',
            disableSortBy: true,
            Cell: (cell: any) => {
                if (cell.row.original.isDescriptionRow || cell.row.original.isDetailRow) {
                    return null;
                }

                return (
                    <>
                        {(cell.value?.date !== '' || cell.value?.by !== '') ? (
                            <VStack alignItems="start" fontWeight={500}>
                                <Text fontSize="sm">
                                    {localDateTimeIgnoreToday(cell.value.date)}
                                </Text>
                                <HStack>
                                    <Avatar name={cell?.value?.by || ''} size="xs" color="white" />
                                    <Text fontSize="sm">
                                        {cell?.value?.by}
                                    </Text>
                                </HStack>
                            </VStack>
                        ) : (
                            <Icon as={BsDash} />
                        )}
                    </>
                );
            }
        },
        {
            Header: '',
            accessor: 'createAction',
            disableSortBy: true,

            Cell: (cell: any) => {
                if (cell.row.original.isDescriptionRow || cell.row.original.isDetailRow || !canCreateActions) {
                    return null;
                }

                return (
                    <Button
                        float="right"
                        size="xs"
                        colorScheme={cell.row.values.status === 'Failed' ? 'red' : 'gray'}
                        leftIcon={<Icon as={cell.row.values.status === 'Failed' ? BsWrench : BsPlusSquare} />}
                        onClick={() => handleActionClick(cell.row.original)}
                    >
                        Action
                    </Button>
                );
            }
        }
    ], [currentItems]);

    return (
        <>
            {renderImage()}
            {renderActionModal()}
            {isLarge ? (
                <VStack>
                    {currentItems.map((item: any, i: number) => (
                        <Fragment key={i}>
                            {
                                item.newPage && (
                                    (item.tpname !== null && item.tpname !== '') ||
                                    (item.tpdescriptionmd !== null && item.tpdescription !== '')
                                ) && (
                                    <Box
                                        key={`${i}-desc`}
                                        w="100%"
                                        p={4}
                                        pt={3}
                                        bg="gray.50"
                                        rounded="lg"
                                        border="1px solid"
                                        borderColor="gray.200"
                                    >
                                        <VStack alignItems="start" w="100%" spacing={2}>
                                            {item.tpname && (
                                                <Text fontSize="lg" fontWeight={500}>
                                                    {item.tpname}
                                                </Text>
                                            )}
                                            {item.tpdescriptionmd && (
                                                <Text fontSize='sm'>
                                                    <ReactMarkdown
                                                        className="task-report"
                                                        children={item.tpdescriptionmd}
                                                        rehypePlugins={[rehypeRaw] as any}
                                                    />
                                                </Text>
                                            )}
                                        </VStack>
                                    </Box>
                                )
                            }
                            <Box
                                key={i}
                                w="100%"
                                p={3}
                                rounded="lg"
                                bg={item.status === 'Failed' ? 'red.50' : item.status === 'N/A' && item.cansetstatus ? 'gray.50' : 'white'}
                                border="1px solid"
                                borderColor={item.status === 'Failed' ? 'red.100' : item.status === 'N/A' && item.cansetstatus ? 'gray.200' : 'gray.100'}
                            >
                                <VStack w="100%" alignItems="start" spacing={1}>
                                    <HStack w="100%" justify="space-between">
                                        <Text fontWeight={600} fontSize="md">
                                            {item.item.description}
                                        </Text>
                                        {
                                            (item.status === 'Failed' || item.status === 'Passed' ||
                                                (item.status === 'N/A' && item.cansetstatus)) &&
                                            <Badge
                                                alignSelf="flex-start"
                                                variant={item.status === 'Failed' ? 'outline' : 'subtle'}
                                                size="sm"
                                                colorScheme={
                                                    item.status === 'Failed' ? 'red' :
                                                        item.status === 'Passed' ? 'green' : 'gray'
                                                }
                                            >
                                                <HStack>
                                                    <Icon
                                                        as={
                                                            item.status === 'Failed' ? BsXCircleFill :
                                                                item.status === 'Passed' ? BsCheckCircle : BsDashCircle
                                                        }
                                                    />
                                                    <Text>{item.status}</Text>
                                                </HStack>
                                            </Badge>
                                        }
                                    </HStack>

                                    {item.feedback && (
                                        <Text whiteSpace="pre-wrap" fontSize="sm">
                                            {item.feedback}
                                        </Text>
                                    )}

                                    {item.data && (
                                        <HStack fontSize="sm">
                                            <Text fontWeight={500}>Recorded Data:</Text>
                                            <Text>{item.data}</Text>
                                        </HStack>
                                    )}

                                    {item.signedOffBy.date && (
                                        <HStack>
                                            <Text fontWeight={500} fontSize="sm">Completed:</Text>
                                            <Text fontSize="sm">
                                                {localDateTimeIgnoreToday(item.signedOffBy.date)}
                                            </Text>
                                        </HStack>
                                    )}

                                    {item.signedOffBy.by && (
                                        <HStack fontSize="sm">
                                            <Text fontWeight={500}>By:</Text>
                                            <HStack>
                                                <Avatar name={item.signedOffBy.by} size="xs" color="white" />
                                                <Text>{item.signedOffBy.by}</Text>
                                            </HStack>
                                        </HStack>
                                    )}

                                    {item.notes && (
                                        <HStack color="red.600">
                                            <Text fontWeight={500} fontSize="sm">Notes:</Text>
                                            <Text fontSize="sm">{item.notes}</Text>
                                        </HStack>
                                    )}

                                    {item.item.attachments.length > 0 && (
                                        <VStack w="100%" alignItems="baseline">
                                            <Divider mt={2} mb={1} />
                                            <SimpleGrid columns={{ base: 1, sm: 4, lg: 3, xl: 4 }} spacing={2}>
                                                {item.item.attachments?.map((a: any, i: number) => (
                                                    <Image
                                                        bg="white"
                                                        key={i}
                                                        className="hover-pop"
                                                        cursor="pointer"
                                                        src={a.datauri}
                                                        fallbackSrc="https://via.placeholder.com/160"
                                                        alt={a.fileName}
                                                        boxSize={item.tidatainputvariablename === 'signature' ? '' : '7em'}
                                                        boxShadow="xs"
                                                        rounded="lg"
                                                        objectFit="cover"
                                                        onClick={() => getCurrentAttachment(a, item)}
                                                    />
                                                ))}
                                            </SimpleGrid>
                                        </VStack>
                                    )}

                                    {!isEmpty(item.item.feedback) && (
                                        <VStack alignItems="baseline" w="100%" pt={2}>
                                            <Flex flexDirection="row" gap={2.5} maxW="lg">
                                                <Avatar
                                                    size="xs"
                                                    color="white"
                                                    name={item.signedOffBy?.by}
                                                    alignSelf="end"
                                                />
                                                <Flex direction="column" alignItems="flex-start">
                                                    <Box
                                                        px={4}
                                                        py={2}
                                                        bg="gray.100"
                                                        color="gray.900"
                                                        borderRadius="2xl"
                                                        borderBottomLeftRadius={0}
                                                    >
                                                        <Text whiteSpace="pre-wrap" fontSize="sm">
                                                            <ReactMarkdown
                                                                children={item.item.feedback}
                                                                className="task-report"
                                                                rehypePlugins={[rehypeRaw] as any}
                                                            />
                                                        </Text>
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                            <Text fontSize="8pt">
                                                Comment from {item.signedOffBy?.by}
                                            </Text>
                                        </VStack>
                                    )}

                                    {item.actions?.length > 0 && (
                                        <VStack w="100%" alignItems="baseline">
                                            <Divider mt={2} mb={1} />
                                            <HStack>
                                                <Icon as={BsWrench} color="gray.600" />
                                                <Text fontSize="sm" fontWeight={500}>
                                                    Associated Actions
                                                </Text>
                                            </HStack>
                                            {item.actions?.map((a: any, i: number) => (
                                                <Box key={i} onClick={() => navigateTo(`/actions/action/${a.actionid}`, {
                                                    state: { from: 'taskreport' }
                                                })}>
                                                    <HStack w="100%">
                                                        <Text _hover={{ textDecoration: 'underline' }} fontSize="sm">
                                                            {a.actiondescription}
                                                        </Text>
                                                        <Badge
                                                            colorScheme={a.nearlydue ? 'orange' : a.overdue || a.isBlocked ? 'red' : a.isOngoing ? 'blue' : a.isCompleted ? 'green' : 'gray'}
                                                            variant="subtle"
                                                            fontWeight="500"
                                                            size="sm"
                                                        >
                                                            <HStack>
                                                                {!a.notStarted && <Icon as={(a.nearlydue || a.overdue) ? BsClock : a.isBlocked ? BsStopCircle : a.isOngoing ? BsPlayCircle : a.isCompleted ? BsCheckCircle : BsDash} />}
                                                                <Text>{getStatus(a)}</Text>
                                                            </HStack>
                                                        </Badge>
                                                    </HStack>
                                                </Box>
                                            ))}
                                        </VStack>
                                    )}
                                    {
                                        canCreateActions && <Box pt={2}>
                                            <Button
                                                size="xs"
                                                colorScheme={item.status === 'Failed' ? 'red' : 'gray'}
                                                leftIcon={<Icon as={item.status === 'Failed' ? BsWrench : BsPlusSquare} />}
                                                onClick={() => handleActionClick(item)}
                                            >
                                                Action
                                            </Button>
                                        </Box>
                                    }
                                </VStack>
                            </Box>
                        </Fragment>
                    ))}
                </VStack>
            ) : (
                <ObbiTable
                    columns={columns}
                    data={tableData}
                    initialPage={0}
                    pageCount={0}
                    getRowProps={getRowProps}
                    showPager={false}
                    canClickRow={false}
                    getColSpan={(row, columnId) => {
                        if ((row.isDescriptionRow || row.isDetailRow) && columnId === 'status') {
                            return columns.length;
                        }
                        return undefined;
                    }}
                />
            )}
        </>
    );
};